const NumberInput = props => {
  const handleChange = event => {
    props.onChange(event.target.value);
  }

  return(
    <fieldset style={{flex: '1'}}>
      <legend className="text-base">{props.name}</legend>
      <div>
	<label 
	  className="text-base sr-only"
	  htmlFor={props.name}
	>{props.name}</label> 
	<input 
	  value={props.value}
	  onChange={handleChange}
	  className="radioLabel"
	  id={props.name}
	  name={props.name}
	  type="number" 
	  min="0"
	/>
      </div>
    </fieldset>
  )
}

export default NumberInput;
