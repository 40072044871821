import { useState, useEffect } from "react"
import CollectionInput from "./components/CollectionInput/CollectionInput";
import NumberInput from "./components/NumberInput/NumberInput";
import Output from "./components/Output/Output";
import "./App.css";

const App = () => {
  const API_ENDPOINT = process.env.REACT_APP_LOREM_API
  const ORIGIN = process.env.REACT_APP_ORIGIN
  const UNITS = ["paragraphs", "sentences", "words"]
  const [unit, setUnit] = useState(UNITS[0]);
  const [count, setCount] = useState(5);
  const [output, setOutput] = useState("");

  const handleNumberChange = newNumber => {
    setCount(newNumber);
  }

  const handleUnitChange = newUnit => {
    setUnit(newUnit);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  }

  const fetchData = () => {
    const url = new URL(API_ENDPOINT);
    const params = {};
    params[unit] =  count;
    url.search = new URLSearchParams(params).toString();
    fetch(url, { headers: { Origin: ORIGIN } })
      .then(response => response.json())
      .then(json => setOutput(json.text));
  }

  useEffect(() => { fetchData(); }, []);

  return (
    <div className="App">
      <div className="container">
	<header className="margin-gutter">
	  <h1 className="text-base bg-white padding">Log Lady Ipsum</h1>
	</header>
	<main className="margin-gutter bg-white padding">
	  <form 
	    style={{display: "flex", flexWrap: "wrap"}}
	    onSubmit={handleSubmit}
	  >
	    <div style={{flex: "1 1 45%"}}>
	      <CollectionInput 
		name="Unit"
		value={unit}
		collection={UNITS}
		onChange={handleUnitChange}
	      />
	    </div>
	    <div style={{flex: "1 1 45%", display: "flex", width: "100%"}}>
	      <NumberInput 
		name="Count"
		value={count}
		onChange={handleNumberChange}
	      />
	    </div>
	    <div className="margin-vertical" style={{flex: "1 1 100%"}}>
	      <button 
		style={{ flex: "1", display: "block" }}
		className="button"
	      >Submit</button>
	    </div>
	  </form>
	  <Output value={output}/>
	</main>
      </div>
    </div>
  );
}

export default App;
