import { useRef, useEffect } from "react";

const Output = props => {
  const textArea = useRef(null);

  useEffect(() => {
    resizeInput(textArea.current);
  }, [props.value]);

  const resizeInput = input => {
    input.style.height = "auto";
    input.style.height = `${input.scrollHeight}px`;
  }

  const handleCopy = event => {
    textArea.current.select();
    document.execCommand('copy');
    event.target.focus();
    alertCopied(event.target);
  }

  const alertCopied = button => {
    const initialText = button.innerText;
    const activeClass = 'active';
    const alertTimeout = 1250;
    button.innerText = 'Copied!';
    button.classList.add(activeClass);
    setTimeout(() => {
      button.innerText = initialText;
      button.classList.remove(activeClass);
    }, alertTimeout)
  }

  return(
    <>
      <div className="output-wrapper">
	<label htmlFor="lorem_output" className="sr-only">Lorem output</label>
	<textarea
	  ref={textArea}
	  className='textarea'
	  value={props.value}
	  id="lorem_output"
	  readOnly
	></textarea>
      </div>
      <button 
	onClick={handleCopy}
	className="button margin-vertical"
      >Copy to clipboard
      </button>
    </>
  )
}

export default Output;
