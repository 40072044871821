const CollectionInput = props => {
  const handleChange = event => {
    props.onChange(event.target.value);
  }

  return (
    <fieldset style={{display: "flex", flexWrap: "wrap", flex: "1"}}>
      <legend className="text-base">{props.name}</legend>
      { 
        props.collection.map((unit, index) => {
          return (
            <div key={`${unit}-${index}`}>
	      <input 
	        value={unit}
	        id={unit}
	        checked={props.value === unit}
	        onChange={handleChange}
		className="radioInput sr-only"
	        name={props.name}
	        type="radio"/>
	      <label 
		className="radioLabel capitalize"
		htmlFor={unit}
	      >{unit}</label>
            </div>
          )
        })
      }
    </fieldset>
  )
}

export default CollectionInput;;
